import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader, Select, Stack, Switch, Tabs, TextInput } from '@mantine/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as AuthContext } from '../../../providers/AuthProvider';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import { Context as EcommerceContext } from '../../../providers/EcommerceProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import {
  CURRENCY_TYPE_ENUM,
  ECOM_STORE_STOCK_CONTENT_ENUM
} from '../../../config/constants';
import SearchBar from '../../common/SearchBar';

const AddEcomStoreModal = ({ isOpen, onClose }) => {
  const history = useHistory();
  const { state, appendState, fetchAssociations } = useContext(
    TeamManagementContext
  );
  const { state: authState } = useContext(AuthContext);
  const { createEcomStore, createEcomStoreForAssociation } = useContext(
    EcommerceContext
  );
  const [searchResults, setSearchResults] = useState([]);
  const [formState, setFormState] = useState({
    name: '',
    domain: '',
    supportEmail: '',
    sandboxEnabled: true,
    fkCurrencyType: CURRENCY_TYPE_ENUM.CAD,
    stockContentId: '',
    pkAssociation: '',
    assignAdmin: false,
    activeTab: 'association',
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        name: '',
        domain: '',
        supportEmail: '',
        sandboxEnabled: true,
        fkCurrencyType: CURRENCY_TYPE_ENUM.CAD,
        stockContentId: '',
        pkAssociation: '',
        assignAdmin: false,
        activeTab: 'association',
        loading: false
      });
      if (
        !state.associations.loading &&
        state.associations.value.length === 0
      ) {
        fetchAssociations();
      }
    }
  }, [isOpen]);

  const searchForAssociation = (term) => {
    setSearchResults(
      state.associations.value
        .filter(
          (a) =>
            a.name.toLowerCase().includes(term.toLowerCase()) ||
            a.domain.toLowerCase().includes(term.toLowerCase())
        )
        .filter((a, index) => index < 7)
        .map((a) => ({
          title: a.name,
          key: a.pkAssociation,
          description: a.domain,
          image: a.image,
          data: a
        }))
    );
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} title="Create Store">
      <FormSection
        cancelTitle="Cancel"
        error={formState.error}
        isLoading={formState.loading}
        onCancel={onClose}
        onSubmit={() => {
          setFormState({
            ...formState,
            loading: true
          });
          if (formState.activeTab === 'association') {
            createEcomStoreForAssociation(
              {
                adminUserPk: formState.assignAdmin
                  ? authState.subscriptionData.user.pkUser
                  : null,
                stockContentId: formState.stockContentId,
                pkAssociation: formState.pkAssociation
              },
              () => {
                onClose();
                triggerNotification(
                  'Store created successfully',
                  'Success',
                  'green'
                );
              },
              (error) => {
                triggerNotification(error);
                setFormState({
                  ...formState,
                  loading: false
                });
              }
            );
          }
          else {
            createEcomStore(
              formState,
              (data) => {
                onClose();
                history.push(`/ecommerce/stores/${data.pkEcomStore}`);
              },
              (error) => {
                triggerNotification(error);
                setFormState({
                  ...formState,
                  loading: false
                });
              }
            );
          }
        }}
        submitColor="blue"
        submitTitle="Create"
      >
        <Tabs
          onTabChange={(activeTab) =>
            setFormState({
              ...formState,
              activeTab
            })
          }
          value={formState.activeTab}
        >
          <Tabs.List>
            <Tabs.Tab value="association">By Association</Tabs.Tab>
            <Tabs.Tab value="default">Default</Tabs.Tab>
          </Tabs.List>
        </Tabs>
        {formState.activeTab === 'association' ? (
          <Stack style={{ gap: 20 }}>
            {state.associations.loading ? (
              <Stack
                sx={{
                  flex: 1,
                  padding: 20,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Loader color="dark" size={50} />
              </Stack>
            ) : (
              <>
                <SearchBar
                  disabled={formState.loading}
                  loading={state.teamSearchResults.loading}
                  onClear={() => {
                    appendState({
                      teamSearchResults: { value: [], loading: false }
                    });
                  }}
                  onResultSelect={(association) =>
                    setFormState({
                      ...formState,
                      error: '',
                      pkAssociation: association?.pkAssociation ?? ''
                    })
                  }
                  onSearch={(term) => searchForAssociation(term)}
                  placeholder="Find the association by name/domain"
                  results={searchResults}
                />

                {formState.pkAssociation && (
                  <>
                    <Select
                      clearable
                      data={[
                        {
                          label: 'Generic',
                          value: ECOM_STORE_STOCK_CONTENT_ENUM.GENERIC.toString()
                        },
                        {
                          label: 'Hockey',
                          value: ECOM_STORE_STOCK_CONTENT_ENUM.HOCKEY.toString()
                        }
                      ]}
                      disabled={formState.loading}
                      label="Stock content"
                      onChange={(v) =>
                        setFormState({
                          ...formState,
                          stockContentId: v
                        })
                      }
                      required
                      searchable
                      value={formState.stockContentId}
                    />

                    <Switch
                      checked={formState.formState}
                      disabled={formState.loading}
                      label="Assign yourself as admin"
                      onChange={() =>
                        setFormState({
                          ...formState,
                          assignAdmin: !formState.assignAdmin
                        })
                      }
                      style={{ marginBottom: 10 }}
                    />
                  </>
                )}
              </>
            )}
          </Stack>
        ) : (
          <Stack>
            <TextInput
              disabled={formState.loading}
              label="Store Name"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.currentTarget.value
                })
              }
              required
              value={formState.name}
            />

            <TextInput
              disabled={formState.loading}
              label="Domain"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  domain: e.currentTarget.value
                })
              }
              value={formState.domain}
            />

            <TextInput
              disabled={formState.loading}
              label="Support Email"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  supportEmail: e.currentTarget.value
                })
              }
              type="email"
              value={formState.supportEmail}
            />

            <Select
              data={[
                {
                  label: 'CAD',
                  value: CURRENCY_TYPE_ENUM.CAD
                },
                {
                  label: 'USD',
                  value: CURRENCY_TYPE_ENUM.USD
                }
              ]}
              disabled={formState.loading}
              label="Currency"
              onChange={(v) =>
                setFormState({
                  ...formState,
                  fkCurrencyType: v
                })
              }
              value={formState.fkCurrencyType}
            />

            <Switch
              checked={formState.sandboxEnabled}
              disabled={formState.loading}
              label="Start in sandbox mode"
              onChange={() =>
                setFormState({
                  ...formState,
                  sandboxEnabled: !formState.sandboxEnabled
                })
              }
              style={{ marginBottom: 10 }}
            />

            <Select
              clearable
              data={[
                {
                  label: 'Generic',
                  value: ECOM_STORE_STOCK_CONTENT_ENUM.GENERIC.toString()
                },
                {
                  label: 'Hockey',
                  value: ECOM_STORE_STOCK_CONTENT_ENUM.HOCKEY.toString()
                }
              ]}
              label="Stock content"
              onChange={(v) =>
                setFormState({
                  ...formState,
                  stockContentId: v
                })
              }
              searchable
              value={formState.stockContentId}
            />
          </Stack>
        )}
      </FormSection>
    </ResponsiveModal>
  );
};

AddEcomStoreModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddEcomStoreModal;
