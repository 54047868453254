import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionIcon,
  Alert,
  Button,
  Checkbox,
  Group,
  Image,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea
} from '@mantine/core';
import { NumericFormat } from 'react-number-format';
import { Menu2, Pencil, Plus, X } from 'tabler-icons-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Context as RegistrationContext } from '../../../providers/RegistrationProvider';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import RegMerchPackageImageModal from './RegMerchPackageImageModal';
import { uploadFileToStorage } from '../../../helpers/awsHelper';
import { triggerNotification } from '../../../helpers/notificationHelper';
import RegMerchPackageInputModal from './RegMerchPackageInputModal';
import { currencyFormat } from '../../../helpers/formatHelper';
import { PAYMENT_PROVIDER_LIST } from '../../../config/constants';
import PaymentPriceItem from '../../common/PaymentPriceItem';

const RegMerchPackageModal = ({
  merchPackage,
  isOpen,
  onClose,
  showDelete,
  copyPackage
}) => {
  const {
    state,
    createMerchPackages,
    updateMerchPackages,
    deleteMerchPackages,
    rebaseRegAssociation,
    fetchRegAssociations
  } = useContext(RegistrationContext);
  const [formState, setFormState] = useState({
    name: '',
    description: '',
    price: 0,
    wholesalePrice: 0,
    isGlobal: false,
    images: [],
    inputs: [],
    selectedInput: null,
    regAssociationMerchPackages: [],
    showImageModal: false,
    isLoading: false,
    showEnableMerchModal: false,
    error: '',
    hasUnsavedChanges: false,
    showCloseConfirmation: false
  });

  const regAssociationOptions = state.regAssociations.value
    .sort((a, b) => a.association.name.localeCompare(b.association.name))
    .map((a) => ({
      label: `${a.pkRegAssociation} - ${a.association.name}`,
      value: a.pkRegAssociation.toString()
    }));

  const selectedRegAssociation = formState.regAssociationMerchPackages[0]
    ? state.regAssociations.value.find(
        (a) =>
          a.pkRegAssociation.toString() ===
          formState.regAssociationMerchPackages[0].fkRegAssociation
      )
    : null;

  const associationSplitAmount = formState.regAssociationMerchPackages[0]
    ?.associationSplit
    ? formState.regAssociationMerchPackages[0].associationSplit
    : 0;

  const passFees = formState.regAssociationMerchPackages[0]?.passFees || false;
  const wholesaleToAssociation =
    formState.regAssociationMerchPackages[0]?.wholesaleToAssociation || false;

  const sportsheadzCut =
    formState.price - formState.wholesalePrice - associationSplitAmount;

  const paymentProvider = PAYMENT_PROVIDER_LIST.find(
    (p) =>
      p.value ===
      selectedRegAssociation?.regAssociationPaymentProvider?.fkPaymentProvider
  );

  const serviceFee = paymentProvider
    ? formState.price * paymentProvider.percentageFee + paymentProvider.flatFee
    : 0;

  const transactionTotal = passFees
    ? formState.price + serviceFee
    : formState.price;

  useEffect(() => {
    if (isOpen) {
      if (merchPackage) {
        setFormState({
          name: merchPackage.name,
          description: merchPackage.description,
          price: merchPackage.price,
          wholesalePrice: merchPackage.wholesalePrice,
          wholesaleToAssociation: merchPackage.wholesaleToAssociation,
          isGlobal: merchPackage.isGlobal,
          images: merchPackage.merchPackageImages
            .sort((a, b) => a.sort - b.sort)
            .map((i) => ({
              key: i.pkMerchPackageImage,
              file: null,
              preview: i.image,
              url: i.image,
              sort: i.sort
            })),
          inputs: merchPackage.merchPackageInputs
            .sort((a, b) => a.sort - b.sort)
            .map((i) => ({
              ...i,
              key: i.pkMerchPackageInput,
              options: i.merchPackageInputOptions.map((io) => ({
                ...io,
                key: io.pkMerchPackageInputOption
              }))
            })),
          selectedInput: null,
          regAssociationMerchPackages: merchPackage.regAssociationMerchPackages.map(
            (p) => ({
              fkRegAssociation: p.fkRegAssociation.toString(),
              associationSplit: p.associationSplit,
              passFees: p.passFees,
              wholesaleToAssociation: p.wholesaleToAssociation
            })
          ),
          showImageModal: false,
          isLoading: false,
          showEnableMerchModal: false,
          error: '',
          hasUnsavedChanges: false,
          showCloseConfirmation: false
        });
      }
      else {
        setFormState({
          name: '',
          description: '',
          price: 0,
          wholesalePrice: 0,
          isGlobal: false,
          images: [],
          inputs: [],
          selectedInput: null,
          regAssociationMerchPackages: [],
          showImageModal: false,
          isLoading: false,
          showEnableMerchModal: false,
          error: '',
          hasUnsavedChanges: false,
          showCloseConfirmation: false
        });
      }
    }
  }, [isOpen]);

  const uploadFileContentRecursively = (images) => {
    if (!images.some((i) => i.file)) {
    }
    else {
      for (const image of images) {
        if (image.file) {
          uploadFileToStorage(
            `merch-package-image-${new Date().getTime()}`,
            image.file,
            (location) => {
              image.file = null;
              image.url = location;
              image.preview = location;
              // eslint-disable-next-line no-unused-vars
              uploadFileContentRecursively([...images]);
            },
            () => {
              setFormState({
                ...formState,
                error: 'Error uploading file',
                isLoading: false
              });
              triggerNotification('Error uploading file');
            }
          );
          break;
        }
      }
    }
  };

  const onPriceChange = (
    { price, wholesalePrice, associationSplit },
    validateInputs = false
  ) => {
    let newWholesalePrice = wholesalePrice;
    let newAssociationSplit = associationSplit;

    if (validateInputs) {
      if (price > 0 && price < newWholesalePrice) {
        newWholesalePrice = price;
      }

      if (
        (price > 0 &&
          newWholesalePrice > 0 &&
          newAssociationSplit > price - newWholesalePrice) ||
        price - newWholesalePrice === 0
      ) {
        newAssociationSplit = price - newWholesalePrice;
      }
    }

    setFormState({
      ...formState,
      price,
      wholesalePrice: newWholesalePrice,
      regAssociationMerchPackages: [
        {
          ...formState.regAssociationMerchPackages[0],
          fkRegAssociation:
            formState.regAssociationMerchPackages[0]?.fkRegAssociation || '',
          associationSplit: newAssociationSplit
        }
      ],
      hasUnsavedChanges: true
    });
  };

  return (
    <ResponsiveModal
      closeOnClickOutside={false}
      isOpen={isOpen}
      onClose={() => {
        if (formState.hasUnsavedChanges) {
          setFormState({
            ...formState,
            showCloseConfirmation: true
          });
        }
        else {
          onClose();
        }
      }}
      title={
        showDelete || formState.showDelete
          ? 'Delete Package'
          : merchPackage && !copyPackage
          ? 'Update Package'
          : 'Create Package'
      }
      transition="none"
      transitionDuration={0}
    >
      {showDelete || formState.showDelete ? (
        <FormSection
          isLoading={formState.isLoading}
          onCancel={() => {
            if (showDelete) {
              onClose();
            }
            else {
              setFormState({
                ...formState,
                showDelete: false
              });
            }
          }}
          onSubmit={() => {
            setFormState({
              ...formState,
              isLoading: true
            });
            deleteMerchPackages(
              [merchPackage.pkMerchPackage],
              onClose,
              (error) => {
                setFormState({
                  ...formState,
                  isLoading: false,
                  error
                });
                triggerNotification(error);
              }
            );
          }}
          submitColor="red"
          submitTitle="Delete Package"
        >
          <Text style={{ textAlign: 'center', marginTop: 20 }}>
            Are you sure you want to delete <b>{merchPackage.name}</b>?
          </Text>
        </FormSection>
      ) : (
        <FormSection
          isDisabled={formState.enableMerchLoading}
          isLoading={formState.isLoading && !formState.enableMerchLoading}
          onCancel={onClose}
          onSubmit={() => {
            setFormState({
              ...formState,
              isLoading: true
            });
            uploadFileContentRecursively([...formState.images]);
          }}
          submitTitle={
            merchPackage && !copyPackage ? 'Update Package' : 'Create Package'
          }
        >
          <Stack style={{ gap: 20 }}>
            <TextInput
              disabled={formState.isLoading}
              label="Name"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.currentTarget.value,
                  hasUnsavedChanges: true
                })
              }
              required
              value={formState.name || ''}
            />

            <Textarea
              autosize
              disabled={formState.isLoading}
              label="Description"
              maxRows={12}
              minRows={4}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  description: e.currentTarget.value,
                  hasUnsavedChanges: true
                })
              }
              value={formState.description || ''}
            />

            <Stack style={{ gap: 5 }}>
              <Group style={{ justifyContent: 'space-between' }}>
                <Text style={{ fontWeight: 500 }}>Images</Text>
                <Button
                  compact
                  disabled={formState.isLoading}
                  leftIcon={<Plus />}
                  onClick={() =>
                    setFormState({
                      ...formState,
                      showImageModal: true,
                      hasUnsavedChanges: true
                    })
                  }
                >
                  Add Image
                </Button>
              </Group>
              {formState.images.length === 0 ? (
                <Stack
                  style={{ padding: '30px 0px', border: 'solid 1px lightgrey' }}
                >
                  <Text style={{ textAlign: 'center' }}>
                    No images available
                  </Text>
                </Stack>
              ) : (
                <DragDropContext
                  onDragEnd={(e) => {
                    if (e.source.index === e.destination.index) {
                      return;
                    }

                    const imagesCopy = [...formState.images];
                    const oldIndex = e.source.index;
                    const newIndex = e.destination.index;

                    const isGreaterIndex = newIndex > oldIndex;
                    for (const image of imagesCopy) {
                      if (image.sort === oldIndex) {
                        image.sort = newIndex;
                      }
                      else if (
                        isGreaterIndex &&
                        image.sort >= oldIndex &&
                        image.sort <= newIndex
                      ) {
                        image.sort -= 1;
                      }
                      else if (
                        image.sort <= oldIndex &&
                        image.sort >= newIndex
                      ) {
                        image.sort += 1;
                      }
                    }
                    setFormState({
                      ...formState,
                      images: imagesCopy
                        .sort((a, b) => a.sort - b.sort)
                        .map((i, index) => ({
                          ...i,
                          sort: index
                        })),
                      hasUnsavedChanges: true
                    });
                  }}
                >
                  <Droppable droppableId="image-droppable">
                    {(dropProvided) => (
                      <div
                        {...dropProvided.droppableProps}
                        ref={dropProvided.innerRef}
                      >
                        <Stack style={{ gap: 5 }}>
                          {formState.images
                            .sort((a, b) => a.sort - b.sort)
                            .map((i, index) => (
                              <Draggable
                                key={i.key}
                                draggableId={i.key.toString()}
                                index={index}
                              >
                                {(dragProvided) => (
                                  <div
                                    ref={dragProvided.innerRef}
                                    {...dragProvided.draggableProps}
                                    {...dragProvided.dragHandleProps}
                                  >
                                    <Group
                                      style={{
                                        flex: 1,
                                        padding: '10px 20px',
                                        justifyContent: 'space-between',
                                        border: 'solid 1px lightgrey'
                                      }}
                                    >
                                      <Group style={{ alignItems: 'center' }}>
                                        <ActionIcon
                                          color="dark"
                                          disabled={formState.isLoading}
                                          variant="subtle"
                                        >
                                          <Menu2 />
                                        </ActionIcon>
                                        <Text weight={500}>#{index + 1}.</Text>
                                        <Image
                                          fit="contain"
                                          height={50}
                                          src={i.preview}
                                          width={50}
                                        />
                                      </Group>

                                      <ActionIcon
                                        color="red"
                                        disabled={formState.isLoading}
                                        onClick={() =>
                                          setFormState({
                                            ...formState,
                                            images: formState.images.filter(
                                              (j) => j.key !== i.key
                                            ),
                                            hasUnsavedChanges: true
                                          })
                                        }
                                        variant="subtle"
                                      >
                                        <X />
                                      </ActionIcon>
                                    </Group>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        </Stack>
                        {dropProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Stack>

            <Stack style={{ gap: 5 }}>
              <Group style={{ justifyContent: 'space-between' }}>
                <Text style={{ fontWeight: 500 }}>Inputs</Text>
                <Button
                  compact
                  disabled={formState.isLoading}
                  leftIcon={<Plus />}
                  onClick={() =>
                    setFormState({
                      ...formState,
                      showInputModal: true,
                      hasUnsavedChanges: true
                    })
                  }
                >
                  Add Input
                </Button>
              </Group>
              {formState.inputs.length === 0 ? (
                <Stack
                  style={{ padding: '30px 0px', border: 'solid 1px lightgrey' }}
                >
                  <Text style={{ textAlign: 'center' }}>
                    No inputs available
                  </Text>
                </Stack>
              ) : (
                <DragDropContext
                  onDragEnd={(e) => {
                    if (e.source.index === e.destination.index) {
                      return;
                    }

                    const inputsCopy = [...formState.inputs];
                    const oldIndex = e.source.index;
                    const newIndex = e.destination.index;

                    const isGreaterIndex = newIndex > oldIndex;
                    for (const input of inputsCopy) {
                      if (input.sort === oldIndex) {
                        input.sort = newIndex;
                      }
                      else if (
                        isGreaterIndex &&
                        input.sort >= oldIndex &&
                        input.sort <= newIndex
                      ) {
                        input.sort -= 1;
                      }
                      else if (
                        input.sort <= oldIndex &&
                        input.sort >= newIndex
                      ) {
                        input.sort += 1;
                      }
                    }
                    setFormState({
                      ...formState,
                      inputs: inputsCopy
                        .sort((a, b) => a.sort - b.sort)
                        .map((i, index) => ({
                          ...i,
                          sort: index
                        })),
                      hasUnsavedChanges: true
                    });
                  }}
                >
                  <Droppable droppableId="input-droppable">
                    {(dropProvided) => (
                      <div
                        {...dropProvided.droppableProps}
                        ref={dropProvided.innerRef}
                      >
                        <Stack style={{ gap: 5 }}>
                          {formState.inputs
                            .sort((a, b) => a.sort - b.sort)
                            .map((i, index) => (
                              <Draggable
                                key={i.key}
                                draggableId={i.key.toString()}
                                index={index}
                              >
                                {(dragProvided) => (
                                  <div
                                    ref={dragProvided.innerRef}
                                    {...dragProvided.draggableProps}
                                    {...dragProvided.dragHandleProps}
                                  >
                                    <Group
                                      style={{
                                        flex: 1,
                                        padding: '10px 20px',
                                        justifyContent: 'space-between',
                                        border: 'solid 1px lightgrey'
                                      }}
                                    >
                                      <Group style={{ alignItems: 'center' }}>
                                        <ActionIcon
                                          color="dark"
                                          disabled={formState.isLoading}
                                          variant="subtle"
                                        >
                                          <Menu2 />
                                        </ActionIcon>
                                        <Text weight={500}>
                                          #{index + 1}. {i.label}
                                        </Text>
                                      </Group>

                                      <Group style={{ gap: 5 }}>
                                        <ActionIcon
                                          color="blue"
                                          disabled={formState.isLoading}
                                          onClick={() =>
                                            setFormState({
                                              ...formState,
                                              showInputModal: true,
                                              selectedInput: i,
                                              hasUnsavedChanges: true
                                            })
                                          }
                                          variant="subtle"
                                        >
                                          <Pencil />
                                        </ActionIcon>
                                        <ActionIcon
                                          color="red"
                                          disabled={formState.isLoading}
                                          onClick={() =>
                                            setFormState({
                                              ...formState,
                                              inputs: formState.inputs.filter(
                                                (j) => j.key !== i.key
                                              ),
                                              hasUnsavedChanges: true
                                            })
                                          }
                                          variant="subtle"
                                        >
                                          <X />
                                        </ActionIcon>
                                      </Group>
                                    </Group>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        </Stack>
                        {dropProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Stack>

            <Checkbox
              checked={formState.isGlobal}
              disabled={formState.isLoading}
              label="Available globally for all merch-enabled asssociations"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  isGlobal: e.currentTarget.checked,
                  hasUnsavedChanges: true
                })
              }
              style={{ fontWeight: 500, marginTop: 10 }}
            />

            {!formState.isGlobal && (
              <>
                <Select
                  clearable
                  data={regAssociationOptions}
                  disabled={formState.isLoading}
                  label="Association"
                  onChange={(value) =>
                    setFormState({
                      ...formState,
                      regAssociationMerchPackages: [
                        {
                          ...formState.regAssociationMerchPackages[0],
                          fkRegAssociation: value,
                          associationSplit: associationSplitAmount
                        }
                      ],
                      hasUnsavedChanges: true
                    })
                  }
                  required
                  searchable
                  value={
                    formState.regAssociationMerchPackages[0]
                      ?.fkRegAssociation || ''
                  }
                />
                {selectedRegAssociation &&
                  !selectedRegAssociation.merchEnabled && (
                    <Alert color="red" title="Merch Disabled" variant="outline">
                      <Stack style={{ alignContent: 'center' }}>
                        <Stack style={{ gap: 5 }}>
                          <Text weight={500}>
                            This association is not merch-enabled.
                          </Text>
                          <Text>
                            You must enable merch for this association before
                            you can create merch packages for it.
                          </Text>
                        </Stack>
                        <Button
                          color="blue"
                          disabled={formState.isLoading}
                          onClick={() =>
                            setFormState({
                              ...formState,
                              showEnableMerchModal: true,
                              hasUnsavedChanges: true
                            })
                          }
                          style={{
                            maxWidth: 250,
                            alignSelf: 'center',
                            width: '100%'
                          }}
                          variant="outline"
                        >
                          Enable Merch
                        </Button>
                      </Stack>
                    </Alert>
                  )}
              </>
            )}

            <NumericFormat
              allowNegative={false}
              customInput={TextInput}
              decimalScale={2}
              disabled={formState.isLoading}
              fixedDecimalScale
              label="Purchase Price"
              onBlur={() =>
                onPriceChange(
                  {
                    price: formState.price,
                    wholesalePrice: formState.wholesalePrice,
                    associationSplit: associationSplitAmount
                  },
                  true
                )
              }
              onValueChange={(values) =>
                onPriceChange({
                  price: values.floatValue * 100,
                  wholesalePrice: formState.wholesalePrice,
                  associationSplit: associationSplitAmount
                })
              }
              prefix="$"
              required
              thousandSeparator
              value={formState.price ? formState.price / 100 : 0}
            />

            <NumericFormat
              allowNegative={false}
              customInput={TextInput}
              decimalScale={2}
              disabled={formState.isLoading}
              fixedDecimalScale
              label="Wholesale Price"
              onBlur={() =>
                onPriceChange(
                  {
                    price: formState.price,
                    wholesalePrice: formState.wholesalePrice,
                    associationSplit: associationSplitAmount
                  },
                  true
                )
              }
              onValueChange={(values) => {
                onPriceChange({
                  price: formState.price,
                  wholesalePrice: values.floatValue * 100,
                  associationSplit: associationSplitAmount
                });
              }}
              prefix="$"
              required
              thousandSeparator
              value={
                formState.wholesalePrice ? formState.wholesalePrice / 100 : 0
              }
            />

            <NumericFormat
              allowNegative={false}
              customInput={TextInput}
              decimalScale={2}
              disabled={formState.isLoading}
              fixedDecimalScale
              label="Association Split"
              onBlur={() =>
                onPriceChange(
                  {
                    price: formState.price,
                    wholesalePrice: formState.wholesalePrice,
                    associationSplit: associationSplitAmount
                  },
                  true
                )
              }
              onValueChange={(values) => {
                onPriceChange({
                  price: formState.price,
                  wholesalePrice: formState.wholesalePrice,
                  associationSplit: values.floatValue * 100
                });
              }}
              prefix="$"
              required
              thousandSeparator
              value={associationSplitAmount / 100}
            />

            <NumericFormat
              customInput={TextInput}
              decimalScale={2}
              disabled
              fixedDecimalScale
              label="SportsHeadz Split"
              onValueChange={() => {}}
              prefix="$"
              thousandSeparator
              value={sportsheadzCut / 100}
            />

            <Checkbox
              checked={wholesaleToAssociation}
              description="The Association receives the wholesale cost and their spit as one payment."
              disabled={formState.isLoading}
              label="Send wholesale price to association"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  regAssociationMerchPackages: [
                    {
                      ...formState.regAssociationMerchPackages[0],
                      wholesaleToAssociation: e.currentTarget.checked
                    }
                  ],
                  hasUnsavedChanges: true
                })
              }
              style={{ fontWeight: 500, marginTop: 10 }}
            />

            <Checkbox
              checked={passFees}
              disabled={formState.isLoading}
              label="Pass service fees onto customer"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  regAssociationMerchPackages: [
                    {
                      ...formState.regAssociationMerchPackages[0],
                      passFees: e.currentTarget.checked
                    }
                  ],
                  hasUnsavedChanges: true
                })
              }
              style={{ fontWeight: 500, marginTop: 10 }}
            />

            {formState.price > 0 &&
              formState.wholesalePrice > 0 &&
              sportsheadzCut / 100 < 3 && (
                <Alert
                  color={sportsheadzCut === 0 ? 'red' : 'yellow'}
                  title="SportsHeadz Split"
                  variant="outline"
                >
                  <Stack style={{ alignContent: 'center', gap: 5 }}>
                    {sportsheadzCut === 0 ? (
                      <Text weight={500}>
                        SportsHeadz will not receive any split from this
                        package.
                      </Text>
                    ) : (
                      <>
                        <Text>
                          SportsHeadz will only receive a{' '}
                          {currencyFormat(sportsheadzCut / 100)} split from this
                          package.
                        </Text>
                        <Text weight={500}>
                          This is less than the expected $3 minimum.
                        </Text>
                      </>
                    )}
                  </Stack>
                </Alert>
              )}

            {selectedRegAssociation &&
              (!selectedRegAssociation.regAssociationPaymentProvider ? (
                <Alert color="red" title="Passing Fees" variant="outline">
                  <Stack style={{ alignContent: 'center', gap: 5 }}>
                    <Text>
                      The selected association does not have a payment provider
                      onboarded.
                    </Text>
                    <Text weight={500}>
                      You must onboard a payment provider before you can
                      determine the passed on fees
                    </Text>
                  </Stack>
                </Alert>
              ) : (
                <>
                  {!passFees && (
                    <Alert
                      color="yellow"
                      title="Passing Fees"
                      variant="outline"
                    >
                      <Stack style={{ alignContent: 'center', gap: 5 }}>
                        <Text>
                          The customer will not pay the service fees on top of
                          the package price.
                        </Text>
                        <Text>
                          This means that the service fee of{' '}
                          {currencyFormat(serviceFee / 100)} will be taken from
                          the association split.
                        </Text>
                        <Text weight={500}>
                          The association split will be reduced to{' '}
                          {currencyFormat(
                            (associationSplitAmount +
                              (wholesaleToAssociation
                                ? formState.wholesalePrice
                                : 0) -
                              serviceFee) /
                              100
                          )}
                        </Text>
                      </Stack>
                    </Alert>
                  )}
                </>
              ))}

            <Stack style={{ gap: 0 }}>
              <PaymentPriceItem
                label="Transaction Total"
                value={transactionTotal / 100}
                weight={700}
              />
              <PaymentPriceItem label="Service Fee" value={serviceFee / 100} />
              <PaymentPriceItem
                label="Wholesale Price"
                value={formState.wholesalePrice / 100}
              />
              <PaymentPriceItem
                label="SportsHeadz Split"
                value={sportsheadzCut / 100}
              />
              <PaymentPriceItem
                label="Association Split"
                value={
                  (transactionTotal -
                    serviceFee -
                    sportsheadzCut -
                    (wholesaleToAssociation ? 0 : formState.wholesalePrice)) /
                  100
                }
                weight={700}
              />
            </Stack>

            {formState.price > 0 && formState.wholesalePrice > 0 && (
              <Alert color="yellow" title="Transfers" variant="outline">
                <Stack style={{ alignContent: 'center', gap: 5 }}>
                  <Text>
                    Association will receive{' '}
                    {currencyFormat(
                      (associationSplitAmount +
                        (wholesaleToAssociation
                          ? formState.wholesalePrice
                          : 0)) /
                        100
                    )}
                  </Text>
                  <Text>
                    SportsHeadz will receive{' '}
                    {currencyFormat(
                      (sportsheadzCut +
                        (!wholesaleToAssociation
                          ? formState.wholesalePrice
                          : 0)) /
                        100
                    )}
                  </Text>
                </Stack>
              </Alert>
            )}
          </Stack>
        </FormSection>
      )}

      <RegMerchPackageImageModal
        isOpen={formState.showImageModal}
        onAdd={(image) =>
          setFormState({
            ...formState,
            showImageModal: false,
            images: [
              ...formState.images,
              {
                key: new Date().getTime(),
                file: image.file,
                preview: image.preview,
                url: image.url,
                sort:
                  formState.images.length === 0
                    ? 0
                    : Math.max(...formState.images.map((i) => i.sort)) + 1
              }
            ],
            hasUnsavedChanges: true
          })
        }
        onClose={() => setFormState({ ...formState, showImageModal: false })}
      />

      <RegMerchPackageInputModal
        isOpen={formState.showInputModal}
        merchPackageInput={formState.selectedInput}
        onAdd={(input) =>
          setFormState({
            ...formState,
            showInputModal: false,
            selectedInput: null,
            inputs: [
              ...formState.inputs.filter(
                (i) => i.key !== formState.selectedInput?.key
              ),
              {
                ...input,
                key: new Date().getTime(),
                sort:
                  formState.selectedInput?.sort ||
                  (formState.inputs.length === 0
                    ? 0
                    : Math.max(...formState.inputs.map((i) => i.sort)) + 1)
              }
            ],
            hasUnsavedChanges: true
          })
        }
        onClose={() => setFormState({ ...formState, showInputModal: false })}
      />

      <ResponsiveModal
        isOpen={formState.showEnableMerchModal}
        onClose={() =>
          setFormState({
            ...formState,
            showEnableMerchModal: false
          })
        }
        title="Enable Association Merch"
      >
        <FormSection
          isLoading={formState.enableMerchLoading}
          onCancel={() =>
            setFormState({
              ...formState,
              showEnableMerchModal: false
            })
          }
          onSubmit={() => {
            setFormState({
              ...formState,
              enableMerchLoading: true,
              isLoading: true
            });
            rebaseRegAssociation(
              selectedRegAssociation.pkRegAssociation,
              { merchEnabled: true },
              () => {
                fetchRegAssociations();
                setFormState({
                  ...formState,
                  showEnableMerchModal: false,
                  enableMerchLoading: false,
                  isLoading: false
                });
              },
              (error) => {
                triggerNotification(error);
                setFormState({
                  ...formState,
                  enableMerchLoading: false,
                  isLoading: false
                });
              }
            );
          }}
          submitTitle="Enable Merch"
        >
          <Text style={{ textAlign: 'center', marginTop: 20 }}>
            Are you sure you want to enable merch for{' '}
            <b>{selectedRegAssociation?.association.name}</b>?
          </Text>
        </FormSection>
      </ResponsiveModal>
      <ResponsiveModal
        isOpen={formState.showCloseConfirmation}
        onClose={() =>
          setFormState({
            ...formState,
            showCloseConfirmation: false
          })
        }
        title="Cancel Changes"
      >
        <FormSection
          onCancel={() =>
            setFormState({
              ...formState,
              showCloseConfirmation: false
            })
          }
          onSubmit={onClose}
          submitColor="red"
          submitTitle="Close"
        >
          <Text style={{ textAlign: 'center', marginTop: 20 }} weight={500}>
            Are you sure you want to cancel your changes?
          </Text>
        </FormSection>
      </ResponsiveModal>
    </ResponsiveModal>
  );
};

RegMerchPackageModal.propTypes = {
  isOpen: PropTypes.bool,
  merchPackage: PropTypes.object,
  showDelete: PropTypes.bool,
  copyPackage: PropTypes.bool,
  onClose: PropTypes.func
};

export default RegMerchPackageModal;
