import React, { useContext, useEffect } from 'react';
import { Stack, Tabs } from '@mantine/core';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import { Context as EcommerceContext } from '../providers/EcommerceProvider';
import EcomVendorsView from '../components/content/Ecommerce/EcomVendorsView';
import EcomStoreView from '../components/content/Ecommerce/EcomStoreView';

const VIEW_TABS = [
  {
    label: 'Vendors',
    value: 'vendors',
    to: '/ecommerce/vendors',
    isSelected: (pathname) => pathname.startsWith('/ecommerce/vendors')
  },
  {
    label: 'Stores',
    value: 'stores',
    to: '/ecommerce/stores',
    isSelected: (pathname) => pathname.startsWith('/ecommerce/stores')
  }
];

const EcommerceView = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { fetchEcomVendors, fetchEcomStores } = useContext(EcommerceContext);
  const selectedTab = VIEW_TABS.find((t) => t.isSelected(pathname));

  useEffect(() => {
    fetchEcomVendors();
    fetchEcomStores();
  }, []);

  return (
    <Stack sx={{ flex: 1, gap: 0 }}>
      <Stack sx={{ flex: 1, gap: 10 }}>
        <Tabs
          onTabChange={(v) =>
            history.push(VIEW_TABS.find((t) => t.value === v).to)
          }
          value={selectedTab?.value}
          variant="outline"
        >
          <Tabs.List>
            {VIEW_TABS.map((t) => (
              <Tabs.Tab key={t.value} value={t.value}>
                {t.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
        <Switch>
          <Route path="/ecommerce/vendors">
            <EcomVendorsView />
          </Route>
          <Route path="/ecommerce/stores">
            <EcomStoreView />
          </Route>
          <Route path="*">
            <Redirect to="/ecommerce/vendors" />
          </Route>
        </Switch>
      </Stack>
    </Stack>
  );
};

EcommerceView.propTypes = {};

export default EcommerceView;
