const PRODUCTION = true;
const TESTING = false;
const LOCAL_API = false;

const COGNITO_SETTING_DEV = {
  Auth: {
    identityPoolId: 'us-east-1:5111a894-2c2c-41b1-a29a-f717eaa9aa31',
    region: 'us-east-1',
    userPoolId: 'us-east-1_Z2PFfqRF1',
    userPoolWebClientId: '624bs7emni9leiuspgcrrs9j8s',
    mandatorySignIn: true
  },
  Storage: {
    bucket: 'hockeyheadz-storage',
    region: 'us-east-1'
  }
};

const COGNITO_SETTING_PROD = {
  Auth: {
    identityPoolId: 'us-east-1:93ca516d-e2c4-4ad6-b44c-4b05604c8bd7',
    region: 'us-east-1',
    userPoolId: 'us-east-1_FAoqNjX2j',
    userPoolWebClientId: '4fdno1j1g5jukptomkdifu3gao',
    mandatorySignIn: true
  },
  Storage: {
    bucket: 'hockeyheadz-storage',
    region: 'us-east-1'
  }
};

const S3_PUBLIC_URL = 'https://hockeyheadz-storage.s3.amazonaws.com/public/';

const PROVINCE_SELECT_OPTIONS = [
  { key: 'AB', value: 'Alberta', text: 'Alberta' },
  { key: 'BC', value: 'British Columbia', text: 'British Columbia' },
  { key: 'MB', value: 'Manitoba', text: 'Manitoba' },
  { key: 'NB', value: 'New Brunswick', text: 'New Brunswick' },
  {
    key: 'NL',
    value: 'Newfoundland and Labrador',
    text: 'Newfoundland and Labrador'
  },
  { key: 'NT', value: 'Northwest Territories', text: 'Northwest Territories' },
  { key: 'NS', value: 'Nova Scotia', text: 'Nova Scotia' },
  { key: 'NU', value: 'Nunavut', text: 'Nunavut' },
  { key: 'ON', value: 'Ontario', text: 'Ontario' },
  { key: 'PE', value: 'Prince Edward Island', text: 'Prince Edward Island' },
  { key: 'QC', value: 'Quebec', text: 'Quebec' },
  { key: 'SK', value: 'Saskatchewan', text: 'Saskatchewan' },
  { key: 'YT', value: 'Yukon Territory', text: 'Yukon Territory' }
];

const TEAM_MEMBER_ROLE_OPTIONS = [
  { key: '2', value: 'Manager', text: 'Manager' },
  { key: '3', value: 'Coach', text: 'Coach' },
  { key: '4', value: 'Player', text: 'Player' },
  { key: '5', value: 'Guardian', text: 'Guardian' }
];

const TEST_CASE_DATA = {
  auth: {
    email: '',
    password: ''
  },
  team: { pkTeam: 4097, name: 'Test Team', basicPkUser: 121878 },
  user: {
    pkUser: 119936,
    name: 'Test Account',
    roles: [TEAM_MEMBER_ROLE_OPTIONS[0].value]
  },
  association: {
    pkAssociation: 574,
    name: 'Test Association 2'
  },
  venue: {
    pkVenue: 1,
    pkStream: 1
  }
};

const PAYMENT_TYPES = [
  {
    value: '1',
    label: 'Cheque'
  },
  {
    value: '2',
    label: 'Cash'
  },
  {
    value: '3',
    label: 'E-Transfer'
  },
  {
    value: '4',
    label: 'Jumpstart'
  },
  {
    value: '5',
    label: 'Refund'
  },
  {
    value: '6',
    label: 'Invoice'
  },
  {
    value: '7',
    label: 'Stripe'
  },
  {
    value: '8',
    label: 'Afterpay'
  },
  {
    value: '9',
    label: 'Forgiveness'
  },
  {
    value: '10',
    label: 'SportsPay'
  },
  {
    value: '11',
    label: 'SportsPay Installments'
  }
];

const PAYMENT_PROVIDER_ENUM = {
  STRIPE: 1,
  SPORTSPAY: 2
};

const PAYMENT_PROVIDER_LIST = [
  {
    label: 'Stripe',
    value: PAYMENT_PROVIDER_ENUM.STRIPE,
    flatFee: 0,
    percentageFee: 0.03
  },
  {
    label: 'SportsPay',
    value: PAYMENT_PROVIDER_ENUM.SPORTSPAY,
    flatFee: 0,
    percentageFee: 0.0254
  }
];

const PAYMENT_PROVIDER_LINE_ITEM_TYPES = {
  registration: {
    value: 1,
    label: 'Registration'
  },
  merch: {
    value: 2,
    label: 'Merch'
  }
};

const REG_FORM_CONTROL_TYPE_ENUM = {
  TEXT: '1',
  TEXT_INPUT: '2',
  DROP_DOWN: '3',
  DATE: '4',
  RADIO_GROUP: '5',
  CHECKBOX: '6',
  FILE_DOWNLOAD: '7',
  SIGNATURE: '8',
  FILE_UPLOAD: '9'
};

const REG_FORM_CONTROL_TYPES = [
  {
    label: 'Text',
    value: REG_FORM_CONTROL_TYPE_ENUM.TEXT
  },
  {
    label: 'Text Input',
    value: REG_FORM_CONTROL_TYPE_ENUM.TEXT_INPUT
  },
  {
    label: 'Drop-down',
    value: REG_FORM_CONTROL_TYPE_ENUM.DROP_DOWN
  },
  {
    label: 'Date',
    value: REG_FORM_CONTROL_TYPE_ENUM.DATE
  },
  {
    label: 'Radio Group',
    value: REG_FORM_CONTROL_TYPE_ENUM.RADIO_GROUP
  },
  {
    label: 'Checkbox',
    value: REG_FORM_CONTROL_TYPE_ENUM.CHECKBOX
  },
  {
    label: 'File Download',
    value: REG_FORM_CONTROL_TYPE_ENUM.FILE_DOWNLOAD
  },
  {
    label: 'Signature',
    value: REG_FORM_CONTROL_TYPE_ENUM.SIGNATURE
  },
  {
    label: 'File Upload',
    value: REG_FORM_CONTROL_TYPE_ENUM.FILE_UPLOAD
  }
];

const ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM = {
  ASSOCIATION_PAGE_HEADER: 'ASSOCIATION_PAGE_HEADER',
  ORDER_SUMMARY: 'ORDER_SUMMARY ',
  CART_FOOTER: 'CART_FOOTER',
  FORM_SUBMISSION_FOOTER: 'FORM_SUBMISSION_FOOTER'
};

const ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_LIST = [
  {
    label: 'Association Page Header',
    value: ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM.ASSOCIATION_PAGE_HEADER
  },
  {
    label: 'Order Summary',
    value: ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM.ORDER_SUMMARY
  },
  {
    label: 'Cart Footer',
    value: ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM.CART_FOOTER
  },
  {
    label: 'Form Submission Footer',
    value: ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM.FORM_SUBMISSION_FOOTER
  }
];

const REG_PERMISSION_ENUM = {
  MY_LEAGUE: 1,
  COUPONS: 2,
  FORMS: 3,
  FINANCIALS: 4,
  REPORTS: 5,
  EVALUATIONS: 6,
  BRANDING: 7,
  SUPER_ADMIN: 8
};

const CURRENCY_TYPE_ENUM = {
  CAD: 1,
  USD: 2
};

const ECOM_STORE_STOCK_CONTENT_ENUM = {
  GENERIC: 0,
  HOCKEY: 1
};

export {
  PRODUCTION,
  TESTING,
  LOCAL_API,
  COGNITO_SETTING_DEV,
  COGNITO_SETTING_PROD,
  S3_PUBLIC_URL,
  PROVINCE_SELECT_OPTIONS,
  TEAM_MEMBER_ROLE_OPTIONS,
  TEST_CASE_DATA,
  PAYMENT_TYPES,
  PAYMENT_PROVIDER_ENUM,
  PAYMENT_PROVIDER_LIST,
  PAYMENT_PROVIDER_LINE_ITEM_TYPES,
  REG_FORM_CONTROL_TYPE_ENUM,
  REG_FORM_CONTROL_TYPES,
  ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_ENUM,
  ANALYTIC_MERCH_PACKAGE_SHOWCASE_LOCATION_LIST,
  REG_PERMISSION_ENUM,
  CURRENCY_TYPE_ENUM,
  ECOM_STORE_STOCK_CONTENT_ENUM
};
