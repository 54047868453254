import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Group,
  Image,
  ScrollArea,
  Skeleton,
  Stack,
  Text
} from '@mantine/core';
import { Link } from 'react-router-dom';

const EcomStoreList = ({ ecomStores, isLoading }) => (
  <Card shadow="lg" sx={{ flex: 1, padding: 0, border: 'solid 1px lightgrey' }}>
    <ScrollArea
      offsetScrollbars
      style={{ flex: 1, overflow: 'auto', minHeight: '100%', maxHeight: 450 }}
    >
      <Stack
        sx={{
          flex: 1,
          gap: 0
        }}
      >
        {isLoading || !ecomStores ? (
          <>
            {[...Array(6)].map((x, i) => (
              <Group
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                onClick={() => {}}
                sx={{
                  flex: 1,
                  padding: '10px 5px',
                  cursor: 'pointer',
                  flexWrap: 'nowrap'
                }}
              >
                <Skeleton height={40} width={40} />
                <Stack sx={{ flex: 1, gap: 5, overflowWrap: 'anywhere' }}>
                  <Skeleton height={10} width="100%" />
                  <Skeleton height={8} width="75%" />
                </Stack>
              </Group>
            ))}
          </>
        ) : ecomStores.length === 0 ? (
          <Text style={{ textAlign: 'center', marginTop: 10, fontWeight: 500 }}>
            No Stores Available
          </Text>
        ) : (
          ecomStores
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((a) => (
              <Group
                key={a.pkEcomStore}
                component={Link}
                onClick={() => {}}
                sx={{
                  flex: 1,
                  padding: '10px 5px',
                  cursor: 'pointer',
                  flexWrap: 'nowrap',
                  textDecoration: 'none',
                  color: '#000',
                  '&:hover': {
                    color: 'black',
                    backgroundColor: '#f4f4f4'
                  }
                }}
                to={`/ecommerce/stores/${a.pkEcomStore}`}
              >
                <Stack
                  style={{
                    border: `solid 1px ${
                      a.deleted
                        ? '#c40000'
                        : a.sandboxEnabled
                        ? '#eca70a'
                        : '#067D62'
                    }`,
                    height: 40,
                    width: 40,
                    overflow: 'hidden',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Image
                    fit="contain"
                    height={40}
                    src={a.logoImageUrl ?? null}
                    width={40}
                  />
                </Stack>

                <Stack style={{ flex: 1, gap: 5, overflowWrap: 'anywhere' }}>
                  <Text sx={{ fontSize: 16, lineHeight: '18px' }} weight={700}>
                    {a.name}
                  </Text>

                  <Text
                    sx={{
                      fontSize: 14,
                      lineHeight: '16px',
                      overflow: 'hidden'
                    }}
                  >
                    {a.domain}
                  </Text>
                </Stack>
              </Group>
            ))
        )}
      </Stack>
    </ScrollArea>
  </Card>
);

EcomStoreList.propTypes = {
  isLoading: PropTypes.bool,
  ecomStores: PropTypes.array
};

export default EcomStoreList;
