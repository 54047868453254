import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Group,
  Menu,
  Skeleton,
  Stack,
  Text
} from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';
import { getInitialsFromName } from '../../helpers/formatHelper';

const ActionListItem = ({
  header,
  description,
  extraDescriptions,
  avatar,
  icon,
  isLoading,
  showImage,
  actions,
  onAction,
  alwaysShowActions,
  onClick,
  ...rest
}) => {
  const [isHovered, setHovered] = useState(false);

  return !header ? (
    <Group
      {...rest}
      sx={{
        gap: 10,
        flex: 1,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        ...rest.sx
      }}
    >
      <Group sx={{ flex: 1, gap: 10, flexWrap: 'nowrap' }}>
        {showImage && <Skeleton circle height={38} width={38} />}
        <Stack sx={{ gap: 5, flex: 1 }}>
          <Skeleton height={8} width="25%" />
          <Skeleton height={8} width="25%" />
        </Stack>
      </Group>
    </Group>
  ) : (
    <Group
      onClick={() => onClick && onClick()}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...rest}
      sx={{
        gap: 10,
        flex: 1,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: onClick ? 'pointer' : 'default',
        '&:hover': onClick
          ? {
              border: 'solid 1px #ced4da',
              borderBottom: 'solid 2px #ced4da',
              padding: 9
            }
          : null,
        ...rest.sx
      }}
    >
      <Group style={{ flex: 1, gap: 10, flexWrap: 'nowrap' }}>
        {showImage && (
          <Avatar radius="xl" src={avatar} variant="outline">
            <Text sx={{ color: '#000' }}>{getInitialsFromName(header)}</Text>
          </Avatar>
        )}
        <Stack sx={{ gap: 0, overflow: 'hidden' }}>
          <Text sx={{ lineHeight: '16px' }} weight={500}>
            {header}
          </Text>
          <Text
            sx={{
              color: 'grey',
              textOverflow: 'ellipsis',
              lineHeight: '16px',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              lineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden'
            }}
          >
            {description}
          </Text>
          {extraDescriptions?.map((e) =>
            typeof e === 'string' ? (
              <Text key={e} sx={{ lineHeight: '16px' }}>
                {e}
              </Text>
            ) : (
              e && (
                <Text
                  key={e.label}
                  sx={{
                    lineHeight: '16px',
                    color: e.color,
                    fontWeight: e.fontWeight
                  }}
                >
                  {e.label}
                </Text>
              )
            )
          )}
        </Stack>
      </Group>

      {(alwaysShowActions || isHovered) &&
        actions &&
        (actions.length === 1 ? (
          <Button
            color={actions[0].color}
            compact
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onAction(actions[0].value);
            }}
            type="button"
          >
            {actions[0].label}
          </Button>
        ) : (
          <Menu shadow="md" width={200} withinPortal>
            <Menu.Target>
              <Button compact rightIcon={<ChevronDown />} variant="outline">
                Actions
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              {actions.map((a) => (
                <Menu.Item
                  key={a.value}
                  onClick={() => {
                    onAction(a.value);
                  }}
                >
                  {a.label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        ))}
    </Group>
  );
};

ActionListItem.propTypes = {
  avatar: PropTypes.string,
  description: PropTypes.string,
  header: PropTypes.string,
  icon: PropTypes.node,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onAction: PropTypes.func,
  actions: PropTypes.array,
  showImage: PropTypes.bool,
  extraDescriptions: PropTypes.array,
  alwaysShowActions: PropTypes.bool
};

export default ActionListItem;
