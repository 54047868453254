import { Button, Checkbox, Group, Stack, Text, TextInput } from '@mantine/core';
import React, { useContext, useState } from 'react';
import { Plus } from 'tabler-icons-react';
import { Route, Switch } from 'react-router-dom';
import { mq } from '../../../config/theme';
import { Context as EcommerceContext } from '../../../providers/EcommerceProvider';
import EcomStoreList from './EcomStoreList';
import AddEcomStoreModal from './AddEcomStoreModal';
import EcomStoreDetails from './EcomStoreDetails';

const EcomStoreView = () => {
  const { state } = useContext(EcommerceContext);
  const [showAddModal, setShowAddModal] = useState(false);
  const [filterState, setFilterState] = useState({
    name: '',
    showDeleted: false
  });

  return (
    <>
      <Group sx={{ flex: 1, alignItems: 'start' }}>
        <Stack css={{ flex: 1, gap: 10, maxWidth: 350 }}>
          <Group sx={{ flex: 1, justifyContent: 'space-between' }}>
            <Text sx={{ fontSize: 15 }} weight={700}>
              Stores
            </Text>
            <Button
              color="dark"
              leftIcon={<Plus size={16} />}
              onClick={() => setShowAddModal(true)}
              size="compact-xs"
              style={{ fontSize: 14 }}
              variant="outline"
            >
              Create
            </Button>
          </Group>
        </Stack>
      </Group>
      <Group
        sx={mq({
          flex: 1,
          alignItems: 'start',
          flexDirection: ['column', 'column', 'row']
        })}
      >
        <Stack css={{ flex: 1, gap: 5, alignSelf: 'stretch', maxWidth: 350 }}>
          <Checkbox
            checked={filterState.showDeleted}
            label="Show deleted stores"
            onChange={(e) =>
              setFilterState({
                ...filterState,
                showDeleted: e.currentTarget.checked
              })
            }
            style={{ fontWeight: 500 }}
          />
          <TextInput
            onChange={(e) =>
              setFilterState({
                ...filterState,
                name: e.currentTarget.value
              })
            }
            placeholder="Filter by name"
            value={filterState.name}
          />

          <EcomStoreList
            ecomStores={state.ecomStores.value
              .filter(
                (a) =>
                  (filterState.showDeleted || !a.deleted) &&
                  (!filterState.name ||
                    a.name
                      .toLowerCase()
                      .replace(' ', '')
                      .includes(
                        filterState.name?.toLowerCase().replace(' ', '')
                      ))
              )
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={state.ecomStores.loading}
          />
        </Stack>
        <Stack
          sx={{
            flex: 1,
            alignSelf: 'stretch'
          }}
        >
          <Switch>
            <Route path="/ecommerce/stores/:pkEcomStore">
              <EcomStoreDetails />
            </Route>
          </Switch>
        </Stack>
      </Group>

      <AddEcomStoreModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
      />
    </>
  );
};

export default EcomStoreView;
