import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Stack, Switch, TextInput } from '@mantine/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ResponsiveModal from '../../common/ResponsiveModal';
import FormSection from '../../common/FormSection';
import { Context as EcommerceContext } from '../../../providers/EcommerceProvider';
import { triggerNotification } from '../../../helpers/notificationHelper';
import { CURRENCY_TYPE_ENUM } from '../../../config/constants';

const AddEcomVendorModal = ({ isOpen, onClose }) => {
  const history = useHistory();
  const { createEcomVendor } = useContext(EcommerceContext);
  const [formState, setFormState] = useState({
    name: '',
    domain: '',
    supportEmail: '',
    sandboxEnabled: true,
    fkCurrencyType: CURRENCY_TYPE_ENUM.CAD,
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        name: '',
        domain: '',
        supportEmail: '',
        sandboxEnabled: true,
        fkCurrencyType: CURRENCY_TYPE_ENUM.CAD,
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} title="Create Vendor">
      <FormSection
        cancelTitle="Cancel"
        error={formState.error}
        isLoading={formState.loading}
        onCancel={onClose}
        onSubmit={() => {
          setFormState({
            ...formState,
            loading: true
          });
          createEcomVendor(
            formState,
            (data) => {
              onClose();
              history.push(`/ecommerce/vendors/${data.pkEcomVendor}`);
            },
            (error) => {
              triggerNotification(error);
              setFormState({
                ...formState,
                loading: false
              });
            }
          );
        }}
        submitColor="blue"
        submitTitle="Create"
      >
        <Stack sx={{ marginTop: 20 }}>
          <TextInput
            disabled={formState.loading}
            label="Vendor Name"
            onChange={(e) =>
              setFormState({
                ...formState,
                name: e.currentTarget.value
              })
            }
            required
            value={formState.name}
          />

          <TextInput
            disabled={formState.loading}
            label="Domain"
            onChange={(e) =>
              setFormState({
                ...formState,
                domain: e.currentTarget.value
              })
            }
            value={formState.domain}
          />

          <TextInput
            disabled={formState.loading}
            label="Support Email"
            onChange={(e) =>
              setFormState({
                ...formState,
                supportEmail: e.currentTarget.value
              })
            }
            type="email"
            value={formState.supportEmail}
          />

          <Select
            data={[
              {
                label: 'CAD',
                value: CURRENCY_TYPE_ENUM.CAD
              },
              {
                label: 'USD',
                value: CURRENCY_TYPE_ENUM.USD
              }
            ]}
            disabled={formState.loading}
            label="Currency"
            onChange={(v) =>
              setFormState({
                ...formState,
                fkCurrencyType: v
              })
            }
            value={formState.fkCurrencyType}
          />

          <Switch
            checked={formState.sandboxEnabled}
            disabled={formState.loading}
            label="Start in sandbox mode"
            onChange={() =>
              setFormState({
                ...formState,
                sandboxEnabled: !formState.sandboxEnabled
              })
            }
          />
        </Stack>
      </FormSection>
    </ResponsiveModal>
  );
};

AddEcomVendorModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddEcomVendorModal;
