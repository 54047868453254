import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Image,
  Menu,
  Placeholder,
  Segment
} from 'semantic-ui-react';
import { css } from '@emotion/react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Badge, Stack } from '@mantine/core';
import View from '../../common/View';
import UserDetails from './UserDetails';
import { mq } from '../../../config/theme';
import { Context as TeamManagementContext } from '../../../providers/TeamManagementProvider';
import UserTeams from './UserTeams';
import UserEvents from './UserEvents';
import userPlaceholder from '../../../images/user_placeholder.png';
import { uploadFileToStorage } from '../../../helpers/awsHelper';
import UserSubscriptions from './UserSubscriptions';
import UserAssociations from './UserAssociations';

const UserView = ({ className }) => {
  const { id } = useParams();
  const location = useLocation();
  const {
    state,
    fetchUser,
    fetchRemoteUsers,
    updateUser,
    reactivateUser
  } = useContext(TeamManagementContext);
  const imageInputRef = useRef();

  useEffect(() => {
    fetchUser(id);
    fetchRemoteUsers(id);
  }, [id]);

  return (
    <Segment
      aria-label="userView"
      css={css`
        margin-top: 10px !important;
        min-height: '50px';
      `}
      loading={state.user.loading}
      role="region"
    >
      {state.user.value && !state.user.loading ? (
        <View
          className={className}
          css={mq({
            flexDirection: ['column', 'row'],
            alignItems: ['center', 'flex-start']
          })}
        >
          <Stack>
            <View css={{ position: 'relative' }}>
              <Image
                aria-label="userImage"
                avatar
                bordered
                circular
                css={{ fontSize: 80, minWidth: '2em' }}
                role="img"
                rounded
                src={
                  state.user.value.user.avatar
                    ? state.user.value.user.avatar
                    : userPlaceholder
                }
              />
              <Icon
                circular
                color="yellow"
                css={{
                  position: 'absolute',
                  bottom: 10,
                  right: 10,
                  cursor: 'pointer'
                }}
                inverted
                name="pencil"
                onClick={() => imageInputRef.current.click()}
                size="big"
              />
              <input
                ref={imageInputRef}
                accept="image/png, image/jpeg"
                data-testid="fileInput"
                hidden
                onChange={(e) =>
                  uploadFileToStorage(
                    state.user.value.user.pkUser,
                    e.target.files[0],
                    (url) =>
                      updateUser(state.user.value.user.pkUser, { avatar: url })
                  )
                }
                type="file"
              />
            </View>
            <Stack>
              {state.user.value.user.deactivated ? (
                <>
                  <Badge color="red" size="lg" variant="outline">
                    Deactivated
                  </Badge>
                  <Button
                    color="green"
                    onClick={() => {
                      reactivateUser({ pkUser: state.user.value.user.pkUser });
                    }}
                  >
                    Reactivate
                  </Button>
                </>
              ) : (
                <Badge color="green" size="lg" variant="outline">
                  Enabled
                </Badge>
              )}
            </Stack>
          </Stack>
          <View
            css={mq({ flexDirection: 'column', flex: 1, marginLeft: [0, 10] })}
          >
            <Menu
              aria-label="userNav"
              pointing
              role="tablist"
              secondary
              stackable
            >
              <Menu.Item
                active={location.pathname.startsWith(`/users/${id}/details`)}
                as={Link}
                name="Details"
                to={`/users/${id}/details`}
              />
              <Menu.Item
                active={location.pathname.startsWith(`/users/${id}/teams`)}
                as={Link}
                name="Teams"
                to={`/users/${id}/teams`}
              />
              <Menu.Item
                active={location.pathname.startsWith(`/users/${id}/events`)}
                as={Link}
                name="Events"
                to={`/users/${id}/events`}
              />
              <Menu.Item
                active={location.pathname.startsWith(
                  `/users/${id}/subscriptions`
                )}
                as={Link}
                name="Subscriptions"
                to={`/users/${id}/subscriptions`}
              />
              <Menu.Item
                active={location.pathname.startsWith(
                  `/users/${id}/associations`
                )}
                as={Link}
                name="Associations"
                to={`/users/${id}/associations`}
              />
            </Menu>
            <Switch>
              <Route path={`/users/${id}/details`}>
                <UserDetails />
              </Route>
              <Route path={`/users/${id}/teams`}>
                <UserTeams />
              </Route>
              <Route path={`/users/${id}/events`}>
                <UserEvents />
              </Route>
              <Route path={`/users/${id}/subscriptions`}>
                <UserSubscriptions />
              </Route>
              <Route path={`/users/${id}/associations`}>
                <UserAssociations />
              </Route>
              <Route path={`/users/${id}`}>
                <Redirect to={`/users/${id}/details`} />
              </Route>
            </Switch>
          </View>
        </View>
      ) : (
        <Placeholder fluid>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
    </Segment>
  );
};

UserView.propTypes = { className: PropTypes.string };

export default UserView;
