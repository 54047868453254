import React, { useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { NotificationsProvider } from '@mantine/notifications';
import View from './components/common/View';

import {
  Provider as AuthProvider,
  Context as AuthContext
} from './providers/AuthProvider';
import { Provider as TeamManagementProvider } from './providers/TeamManagementProvider';
import { Provider as LiveStreamProvider } from './providers/LiveStreamProvider';
import { Provider as EcommerceProvider } from './providers/EcommerceProvider';
import {
  Provider as RegistrationProvider,
  Context as RegistrationContext
} from './providers/RegistrationProvider';
import { Provider as PaymentProvider } from './providers/PaymentProvider';
import SigninView from './views/SigninView';
import LandingView from './views/LandingView';
import PrivateRoute from './components/common/PrivateRoute';
import { palette } from './config/theme';

const App = () => {
  const { tokenSignin, state } = useContext(AuthContext);
  const {
    fetchRegAssociationPaymentWindowsSummary,
    fetchRegCartsWithMissingTransfersSummary
  } = useContext(RegistrationContext);

  useEffect(() => {
    if (!state.tokenAttempted) {
      tokenSignin();
    }
  }, []);

  useEffect(() => {
    if (state.isSignedIn) {
      fetchRegAssociationPaymentWindowsSummary();
      fetchRegCartsWithMissingTransfersSummary();
    }
  }, [state.isSignedIn]);

  return (
    <View
      css={css`
        min-height: 100vh;
        background-color: ${palette.primary.dark};
      `}
    >
      {state.tokenAttempted ? (
        <Router>
          <Switch>
            <Route path="/login">
              <SigninView />
            </Route>
            <PrivateRoute path="/">
              <LandingView />
            </PrivateRoute>
          </Switch>
        </Router>
      ) : (
        <View css={{ alignItem: 'center', justifyContent: 'center', flex: 1 }}>
          <Loader active size="big" />
        </View>
      )}
    </View>
  );
};

export default () => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <AuthProvider>
      <TeamManagementProvider>
        <LiveStreamProvider>
          <RegistrationProvider>
            <EcommerceProvider>
              <PaymentProvider>
                <NotificationsProvider position="top-right">
                  <App />
                </NotificationsProvider>
              </PaymentProvider>
            </EcommerceProvider>
          </RegistrationProvider>
        </LiveStreamProvider>
      </TeamManagementProvider>
    </AuthProvider>
  </MuiPickersUtilsProvider>
);
