import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Group,
  Select,
  Stack,
  Switch,
  Text,
  TextInput
} from '@mantine/core';
import { Context as EcommerceContext } from '../../../providers/EcommerceProvider';
import DeleteItemView from '../../common/DeleteItemView';
import ResponsiveModal from '../../common/ResponsiveModal';
import { CURRENCY_TYPE_ENUM } from '../../../config/constants';

const EcomStoreSettingsView = ({ ecomStore }) => {
  const history = useHistory();
  const { updateEcomStore, deleteEcomStore } = useContext(EcommerceContext);
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: '',
    item: null
  });
  const [formState, setFormState] = useState({
    name: '',
    domain: '',
    supportEmail: '',
    sandboxEnabled: false,
    fkCurrencyType: CURRENCY_TYPE_ENUM.CAD,
    hasUnsavedChanges: false,
    error: null,
    loading: false
  });

  useEffect(() => {
    if (ecomStore) {
      setFormState({
        name: ecomStore.name,
        domain: ecomStore.domain ?? '',
        supportEmail: ecomStore.supportEmail ?? '',
        sandboxEnabled: ecomStore.sandboxEnabled,
        fkCurrencyType: ecomStore.fkCurrencyType,
        hasUnsavedChanges: false,
        error: null,
        loading: false
      });
    }
  }, [ecomStore]);

  return (
    <Stack sx={{ gap: 20 }}>
      <Stack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();

          setFormState({
            ...formState,
            error: null,
            loading: true
          });
          updateEcomStore(
            ecomStore.pkEcomStore,
            formState,
            () => {
              setFormState({
                ...formState,
                loading: false,
                error: null,
                hasUnsavedChanges: false
              });
            },
            (error) => {
              setFormState({
                ...formState,
                loading: false,
                error
              });
            }
          );
        }}
        sx={{ gap: 10 }}
      >
        <TextInput
          disabled={formState.loading}
          label="Store Name"
          onChange={(e) =>
            setFormState({
              ...formState,
              name: e.currentTarget.value,
              hasUnsavedChanges: true
            })
          }
          required
          value={formState.name}
        />

        <TextInput
          disabled={formState.loading}
          label="Domain"
          onChange={(e) =>
            setFormState({
              ...formState,
              domain: e.currentTarget.value,
              hasUnsavedChanges: true
            })
          }
          value={formState.domain}
        />

        <TextInput
          disabled={formState.loading}
          label="Support Email"
          onChange={(e) =>
            setFormState({
              ...formState,
              supportEmail: e.currentTarget.value,
              hasUnsavedChanges: true
            })
          }
          type="email"
          value={formState.supportEmail}
        />

        <Select
          data={[
            {
              label: 'CAD',
              value: CURRENCY_TYPE_ENUM.CAD
            },
            {
              label: 'USD',
              value: CURRENCY_TYPE_ENUM.USD
            }
          ]}
          disabled={formState.loading}
          label="Currency"
          onChange={(v) =>
            setFormState({
              ...formState,
              fkCurrencyType: v
            })
          }
          value={formState.fkCurrencyType}
        />

        <Switch
          checked={formState.sandboxEnabled}
          disabled={formState.loading}
          label="Sandbox Mode"
          onChange={() =>
            setFormState({
              ...formState,
              sandboxEnabled: !formState.sandboxEnabled,
              hasUnsavedChanges: true
            })
          }
        />

        <Group style={{ justifyContent: 'space-between', marginTop: 10 }}>
          <Group>
            <Button
              color={ecomStore.deleted ? 'Reinstate' : 'red'}
              compact
              disabled={formState.loading}
              onClick={() =>
                setModalState({
                  ...modalState,
                  isOpen: true,
                  action: ecomStore.deleted ? 'reinstate' : 'delete',
                  item: ecomStore
                })
              }
              variant={ecomStore.deleted ? 'outline' : 'filled'}
            >
              {ecomStore.deleted ? 'Reinstate Store' : 'Delete Store'}
            </Button>
          </Group>
          {formState.hasUnsavedChanges && (
            <Group sx={{ justifyContent: 'end' }}>
              <Button
                color="dark"
                compact
                disabled={formState.loading}
                onClick={() =>
                  setFormState({
                    name: ecomStore.name,
                    domain: ecomStore.domain ?? '',
                    supportEmail: ecomStore.supportEmail ?? '',
                    sandboxEnabled: ecomStore.sandboxEnabled,
                    fkCurrencyType: ecomStore.fkCurrencyType,
                    hasUnsavedChanges: false,
                    loading: false
                  })
                }
                type="button"
              >
                Cancel
              </Button>
              <Button compact loading={formState.loading} type="submit">
                Save Changes
              </Button>
            </Group>
          )}
        </Group>

        {formState.error && (
          <Alert color="red" variant="outline">
            <Text weight={500}>{formState.error}</Text>
          </Alert>
        )}
      </Stack>

      <ResponsiveModal
        isOpen={modalState.isOpen}
        onClose={() => setModalState({ ...modalState, isOpen: false })}
        title={
          modalState.action === 'reinstate' ? 'Reinstate Store' : 'Delete Store'
        }
      >
        <DeleteItemView
          isLoading={formState.loading}
          itemLabel="Store"
          onCancel={() => setModalState({ ...modalState, isOpen: false })}
          onDelete={() => {
            setFormState({
              ...formState,
              loading: true
            });

            if (modalState.action === 'reinstate') {
              updateEcomStore(
                ecomStore.pkEcomStore,
                { restoreStore: true },
                () => {
                  setModalState({ ...modalState, isOpen: false });
                },
                (error) => {
                  setFormState({
                    ...formState,
                    loading: false,
                    error
                  });
                  setModalState({ ...modalState, isOpen: false });
                }
              );
            }
            else {
              deleteEcomStore(
                ecomStore.pkEcomStore,
                null,
                () => {
                  setModalState({ ...modalState, isOpen: false });
                  history.push(`/ecommerce/stores`);
                },
                (error) => {
                  setFormState({
                    ...formState,
                    isLoading: false,
                    error
                  });
                  setModalState({ ...modalState, isOpen: false });
                }
              );
            }
          }}
          reinstate={modalState.action === 'reinstate'}
          warnings={[
            'The ability to publicly view the store on the ecommerce site.'
          ]}
        />
      </ResponsiveModal>
    </Stack>
  );
};

EcomStoreSettingsView.propTypes = { ecomStore: PropTypes.object };

export default EcomStoreSettingsView;
