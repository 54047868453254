import axios from 'axios';
import { LOCAL_API, PRODUCTION } from '../config/constants';

const sportsheadzApi = axios.create({
  baseURL: LOCAL_API
    ? 'http://localhost:61249'
    : PRODUCTION
    ? 'https://api.sportsheadz.com/prod'
    : 'https://api.sportsheadz.com/stage'
});

export default sportsheadzApi;
